<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 66 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_541_25972)">
      <rect x="3" y="2" width="60" height="60" rx="12" fill="white" />
      <rect
        x="3.75"
        y="2.75"
        width="58.5"
        height="58.5"
        rx="11.25"
        stroke="#EAECF0"
        stroke-width="1.5"
      />
      <path
        d="M37.3749 26.375L41.7499 22M44.2499 19.5L41.7499 22L44.2499 19.5ZM32.2374 31.5125C32.8828 32.1493 33.3959 32.9075 33.747 33.7435C34.0982 34.5794 34.2806 35.4766 34.2836 36.3833C34.2867 37.29 34.1103 38.1884 33.7647 39.0266C33.4192 39.8649 32.9112 40.6265 32.27 41.2677C31.6289 41.9088 30.8673 42.4168 30.029 42.7624C29.1907 43.108 28.2924 43.2843 27.3856 43.2813C26.4789 43.2782 25.5818 43.0959 24.7458 42.7447C23.9099 42.3935 23.1517 41.8804 22.5149 41.235C21.2625 39.9384 20.5696 38.2017 20.5852 36.3991C20.6009 34.5965 21.3239 32.8722 22.5986 31.5975C23.8733 30.3228 25.5976 29.5998 27.4002 29.5841C29.2028 29.5685 30.9395 30.2614 32.2361 31.5138L32.2374 31.5125ZM32.2374 31.5125L37.3749 26.375L32.2374 31.5125ZM37.3749 26.375L41.1249 30.125L45.4999 25.75L41.7499 22L37.3749 26.375Z"
        stroke="#344054"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_541_25972"
        x="0"
        y="0.5"
        width="66"
        height="66"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.5" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_541_25972" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_541_25972"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '67',
    },
    width: {
      type: String,
      default: '66',
    },
    color: {
      type: String,
      default: 'label-text',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
